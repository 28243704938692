.st0 {
  fill: #18988B;
  stroke: #003E52;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #F4DB6C;
  stroke: #003E52;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st2 {
  fill: none;
  stroke: #003E52;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st3 {
  fill: #FFFFFF;
  stroke: #003E52;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st4 {
  fill: #003E52;
}

.st5 {
  fill: #FFFFFF;
}