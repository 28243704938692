body, *.ui {
 font-family: 'Quicksand', sans-serif !important;
 font-weight: 400 !important;
}

.ui.header, h1, h2, h3, h4, h5, h6 {
font-family: 'Quicksand', sans-serif !important;
 font-weight: 550 !important;
}

.ui.header.relaxed, .relaxed {
  margin-bottom: 1.5em !important;
}

.emoji-mart-emoji {
  font-size: inherit !important;
}

.saved-message {
  display: inline-flex;
  margin-left: 1em;
  color: green;
  position: relative;
  top: .3em;
}

.error-message {
  display: inline-flex;
  margin-left: 1em;
  color: red;
  position: relative;
  top: .3em;
}

.drop-zone {
  padding: 1.5em;
  background-color: #f6f6f6;
  border: 1px dashed silver;
  cursor: pointer;
}

.profile-section {
  margin-bottom: 1em;
}

.inline-dropdown-multiple a.ui.label {
  box-shadow: none !important;
  background-color: white;
  font-weight: 700 !important;
  color: black
}

.list.faq .item {
  margin-bottom: 1em;
}

.home-section-list {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.ui.grid.recipe-grid>* {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.ui.grid.recipe-grid>*:first-child {
  padding-left: 0 !important;
}

.ui.grid.recipe-grid>*:last-child {
  padding-right: 0 !important;
}

.item.emphasize {
  background-color: #eeeeee;
  margin-left: -.5em !important;
  padding-top: .5em !important;
  padding: .5em !important;
}

.dropdown.wide-dropdown .visible.menu {
  width: 200% !important;
  max-height: 75vh !important;
}

.dropdown.wider-dropdown .visible.menu {
  width: 350% !important;
  max-height: 75vh !important;
}

.menu-grid .visible.menu {
  display: flex !important;
  flex-wrap: wrap;
}

.menu-grid .visible.menu .item {
  width: 33%;
}